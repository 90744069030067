export default {
  a0: '欢迎注册',
  a1: '请输入用户名',
  a2: '请输入邮箱号',
  a3: '获取验证码',
  a4: '请输入邀请者用户名（必填）',
  a5: '请输入邮箱验证码',
  a6: '下一步',
  a7: '请输入电话号码',
  a8: '请输入Telegram账号',
  a9: '请输入登录密码',
  b0: '请确认登录密码',
  b1: '请输入6位数字安全密码',
  b2: '请确认6位数字安全密码',
  b3: '立即注册',
  b4: '已有账号，去',
  b5: '登录',
  b6: '用户名以字母开头，只包含字母、数字、下划线，且长度为4到16位',
  b7: '请输入正确的邮箱号',
  b8: '请输入邀请者用户名',
  b9: '两次的登录密码输入不一致',
  c0: '两次的安全密码输入不一致',
  c1: '注册成功',
  c2: '已发送',
  c3: '请输入用户名或邮箱号',
  c4: '没有账号，去',
  c5: '注册',
  c6: '忘记密码？',
  c7: '找回密码',
  c8: '请输入新的登录密码',
  c9: '确认',
  d0: '密码重置成功',
  d1: '等级提升',
  d2: '我的地址',
  d3: '暂未绑定',
  d4: '立即绑定',
  d5: '绑定地址',
  d6: '请填写地址',
  d7: '安全密码',
  d8: '请输入安全密码',
  d9: '请填写正确的地址',
  e0: '绑定成功',
  e1: '我的级别',
  e2: '退出登录',
  e3: '重置安全密码',
  e4: '邮箱号',
  e5: '请设置6位数字安全密码',
  e6: '确认安全密码',
  e7: '请确认安全密码',
  e8: '安全密码重置成功',
  e9: '首页',
  f0: '矿机',
  f1: '钱包',
  f2: '我的',
  f3: '我的级别',
  f4: '升级进度',
  f5: '目标业绩',
  f6: '我的业绩',
  f7: '团队去大区业绩',
  f8: '邀请好友',
  f9: '复制',
  g0: '我的社区',
  g1: '团队总用户数',
  g2: '累计总业绩',
  g3: '今日总产量',
  g4: '累计总产量',
  g5: '我的总业绩',
  g6: '总待产价值',
  g7: '总已产价值',
  g8: '已复制',
  g9: '复制失败',
  h0: '公告列表',
  h1: '查看更多',
  h2: '公告详情',
  h3: '矿机商城',
  h4: '我的矿机',
  h5: '日产量',
  h6: '部署时间',
  h7: '出局倍数',
  h8: '暂无数据',
  h9: '记录',
  i0: '剩余部署时间',
  i1: '天',
  i2: '状态',
  i3: '部署中',
  i4: '待产价值',
  i5: '已产数量',
  i6: '已产价值',
  i7: '矿机详情',
  i8: '名称',
  i9: '价格',
  j0: '矿机说明',
  j1: '立即购买',
  j2: '购买数量',
  j3: '金额',
  j4: '可用余额',
  j5: '立即支付',
  j6: '可用余额不足',
  j7: '购买成功',
  j8: '矿机产出',
  j9: '代数收益',
  k0: '级别收益',
  k1: '我的钱包',
  k2: '充值',
  k3: '提现',
  k4: '钱包地址',
  k5: '去绑定',
  k6: '提现数量',
  k7: '我的余额',
  k8: '最小提现数量',
  k9: '手续费',
  l0: '到账数量',
  l1: '请输入提现数量',
  l2: '请输入有效的提现数量',
  l3: '操作成功',
  l4: 'USDT充值',
  l5: '我的BNB Smart Chain网络地址',
  l6: '链接钱包',
  l7: '已链接',
  l8: '充值数量',
  l9: '请填写充值数量',
  m0: '可用数量',
  m1: '确认充值',
  m2: '请使用你绑定的BNB Smart Chain网络地址进行链接',
  m3: '我知道了',
  m4: '请填写有效的充值数量',
  m5: '已充值',
  m6: '充值失败',
  m7: '请使用币安智能链链接',
  m8: '资产钱包记录',
  m9: '购买矿机',
  n0: '扣除',
  n1: '好友列表',
  n2: '用户名',
  n3: '单月销售额',
  n4: '总销售额',
  n5: '时间',
  n6: '有效直推',
  n7: '直推数量',
  n8: '总团队数量',
  n9: '官网',
  o0: '咨询客服',
  o1: '可提KASPA',
  o2: '消息列表'
}
