export default {
  a0: 'Welcome',
  a1: 'Username',
  a2: 'Email Address',
  a3: 'Code',
  a4: 'Referral Code',
  a5: 'Email Code',
  a6: 'Next',
  a7: 'Phone Number',
  a8: 'Telegram Account',
  a9: 'Password',
  b0: 'Re-enter Password',
  b1: 'Safety Pin',
  b2: 'Re-enter Safety Pin',
  b3: 'Register',
  b4: 'Account Registered, ',
  b5: 'Login',
  b6: 'Invalid Username',
  b7: 'Invalid Email',
  b8: 'Invalid Referral Code',
  b9: 'Invalid Password',
  c0: 'Invalid Safety Pin',
  c1: 'Registration Successful',
  c2: 'Sent',
  c3: 'Username/Email',
  c4: 'Invalid Account ',
  c5: 'Sign Up',
  c6: 'Forgot Password',
  c7: 'Reset Password',
  c8: 'New Password',
  c9: 'Confirm',
  d0: 'Complete Reset',
  d1: 'Rank',
  d2: 'Wallet Address',
  d3: 'Not Yet Bind',
  d4: 'Bind Now',
  d5: 'Bind',
  d6: 'Wallet Address',
  d7: 'Safety Pin',
  d8: 'Enter Safety Pin',
  d9: 'Enter Correct Wallet Address',
  e0: 'Bind Successful',
  e1: 'My Rank',
  e2: 'Log Out',
  e3: 'Reset Safety Pin',
  e4: 'Email Address',
  e5: 'New Safety Pin',
  e6: 'Confirm New Safety Pin',
  e7: 'Confirm New Safety Pin',
  e8: 'New Safety Pin Successful',
  e9: 'Main',
  f0: 'Miner',
  f1: 'Wallet',
  f2: 'Profile',
  f3: 'My Rank',
  f4: 'Rank Progress',
  f5: ' Achievement',
  f6: 'Personal Achievement',
  f7: 'Team Achievement',
  f8: 'Invite Friend',
  f9: 'Copy',
  g0: 'My Community',
  g1: 'Team Number',
  g2: 'Total Team Miner',
  g3: 'Today Kaspa',
  g4: 'Total Kaspa',
  g5: 'My Miner',
  g6: 'My Pending',
  g7: 'My Reward',
  g8: 'Copied',
  g9: 'Failed',
  h0: 'Announcement',
  h1: 'More',
  h2: 'Announcement',
  h3: 'Marketplace',
  h4: 'My Miner',
  h5: 'Daily',
  h6: 'Countdown',
  h7: 'Pending X',
  h8: 'No Record',
  h9: 'Record',
  i0: 'Countdown',
  i1: ' Day',
  i2: 'Status',
  i3: 'CD',
  i4: 'Total Pending',
  i5: 'Production',
  i6: 'Value',
  i7: 'Information',
  i8: 'Name',
  i9: 'Price',
  j0: 'Description',
  j1: 'Buy Now',
  j2: 'Quantity',
  j3: 'Price',
  j4: 'Balance',
  j5: 'Buy Now',
  j6: 'Insufficient Balance',
  j7: 'Successful',
  j8: 'Mining',
  j9: 'Level Mining',
  k0: 'Rank Mining',
  k1: 'My Wallet',
  k2: 'Top Up',
  k3: 'Withdraw',
  k4: 'Wallet Address',
  k5: 'Bind Now',
  k6: 'Quantity',
  k7: 'Balance',
  k8: 'Minimum Withdrawal ',
  k9: 'Fee',
  l0: 'Receive',
  l1: 'Enter Amount',
  l2: 'Insufficient',
  l3: 'Successful',
  l4: 'Top Up',
  l5: 'My BNB Smart Chain Wallet Address',
  l6: 'Connect',
  l7: 'Connected',
  l8: 'Top Up Amount',
  l9: 'Please enter amount',
  m0: 'Balance Available',
  m1: 'Confirm',
  m2: 'Please Connect Bound Wallet Address',
  m3: 'Understand',
  m4: 'Please enter available balance',
  m5: 'Successful',
  m6: 'Failed',
  m7: 'Please connect BNB Smart Chain',
  m8: 'Asset Record',
  m9: 'Buy Machine',
  n0: 'Deducted',
  n1: 'My Friends',
  n2: 'Username',
  n3: 'Current Month',
  n4: 'Total',
  n5: 'Time',
  n6: 'Effective Direct Push',
  n7: 'Direct',
  n8: 'Total Member',
  n9: 'Official Page',
  o0: 'Support',
  o1: 'My Wallet Kaspa',
  o2: 'Message List'
}
