<template>
  <div class="header" :style="headerStyle">
    <div class="left">
      <img class="icon_m" src="@/assets/img/icon_menu.png" @click="showMenu = true">
      <img class="logo" src="@/assets/img/logo_s.png">
    </div>
    <div class="right">
      <div v-if="mList.length" class="message" @click="router.push('/message')">
        <img src="@/assets/img/icon_message.svg">
        <div v-if="unread" class="circle"></div>
      </div>
      <div class="lang" @click="changeLang">
        <img class="icon" src="@/assets/img/icon_lang.png">
        <div class="text">{{ langText }}</div>
      </div>
    </div>
  </div>

  <!--左侧菜单-->
  <van-popup
      v-model:show="showMenu"
      position="left"
      duration="0.2"
      :style="{ height: '100%' }"
  >
    <div class="menu">
      <div class="info">
        <div class="avatar">
          <img src="@/assets/img/avatar.png">
        </div>
        <div class="text">
          <div class="name">{{ uInfo.user_nickname }}</div>
          <div class="id">ID:<span>{{ uInfo.id }}</span></div>
          <div class="level">{{ $t('e1') }}:<span>{{ getLevelName(uInfo.level_id) }}</span></div>
        </div>
      </div>
      <div class="list">
        <div class="item" @click="router.push('/level')">
          <img class="icon" src="@/assets/img/icon_menu1.png">
          <div class="text">{{ $t('d1') }}</div>
        </div>
        <div class="item" @click="openReset">
          <img class="icon" src="@/assets/img/icon_menu2.png">
          <div class="text">{{ $t('d7') }}</div>
        </div>
        <div class="item" @click="router.push('/address')">
          <img class="icon" src="@/assets/img/icon_menu3.png">
          <div class="text">{{ $t('d2') }}</div>
        </div>
        <div class="item" @click="openUrl(config.web_url)">
          <img class="icon" src="@/assets/img/icon_web.png">
          <div class="text">{{ $t('n9') }}</div>
        </div>
        <div class="item" @click="openUrl(config.ke_url)">
          <img class="icon" src="@/assets/img/icon_service.png">
          <div class="text">{{ $t('o0') }}</div>
        </div>
        <div class="item" @click="handleExit">
          <img class="icon" src="@/assets/img/icon_menu4.png">
          <div class="text">{{ $t('e2') }}</div>
        </div>
      </div>
    </div>
  </van-popup>

  <!--重置安全密码弹框-->
  <van-popup
      v-model:show="show"
      closeable
      @closed="handleClose"
  >
    <div class="reset">
      <div class="title">{{ $t('e3') }}</div>
      <div class="form">
        <div class="item">
          <div class="label">{{ $t('e4') }}：{{ uInfo.email }}</div>
          <div class="wrap">
            <input v-model="form.verification_code" class="code" :placeholder="$t('a5')">
            <div v-show="!isSend" class="getCode" @click="handleSend">{{ $t('a3') }}</div>
            <div v-show="isSend" class="getCode second">{{ second }}s</div>
          </div>
        </div>
        <div class="item">
          <div class="label">{{ $t('d7') }}</div>
          <input
              v-model="form.pwd"
              type="password"
              maxlength="6"
              class="value"
              :placeholder="$t('e5')"
              @input="form.pwd = form.pwd.replace(/[^\d]/g,'')"
          >
        </div>
        <div class="item">
          <div class="label">{{ $t('e6') }}</div>
          <input
              v-model="conPwd"
              type="password"
              maxlength="6"
              class="value"
              :placeholder="$t('e7')"
              @input="conPwd = conPwd.replace(/[^\d]/g,'')"
          >
        </div>
        <van-button :loading="loading" type="primary" block @click="handleSubmit">{{ $t('c9') }}</van-button>
      </div>
    </div>
  </van-popup>
</template>

<script setup>
  import { ref, onMounted, onBeforeUnmount, computed, reactive } from 'vue'
  import { useRouter } from "vue-router";
  import { userInfo, logout, getEmailCode, pwdReset, msgList } from '@/api/user'
  import { getConfig } from '@/api'
  import { showLoadingToast, closeToast, showToast, showSuccessToast } from "vant";
  import i18n from '@/i18n'

  const router = useRouter()
  const showMenu = ref(false)
  const show = ref(false)

  const emits = defineEmits(['getUserInfo', 'getConfig'])

  const uInfo = ref({})
  userInfo().then(res => {
    if(res.success) {
      uInfo.value = res.data
      emits('getUserInfo', uInfo.value)
    }
  })

  const unread = ref(0)
  const mList = ref([])
  msgList().then(res => {
    if(res.success) {
      unread.value = res.data.not_read_count
      mList.value = res.data.list
    }
  })

  const config = ref({})
  getConfig().then(res => {
    if(res.success) {
      config.value = res.data
      emits('getConfig', res.data)
    }
  })

  const loading = ref(false)
  const form = reactive({
    pwd: '',
    verification_code: ''
  })
  const conPwd = ref('')
  function handleSubmit() {
    if(form.verification_code === '') {
      showToast(i18n.global.t('a5'))
      return
    }
    if(form.pwd === '' || form.pwd.length < 6) {
      showToast(i18n.global.t('e5'))
      return
    }
    if(conPwd.value === '') {
      showToast(i18n.global.t('e7'))
      return
    }
    if(conPwd.value !== form.pwd) {
      showToast(i18n.global.t('c0'))
      return
    }
    loading.value = true
    pwdReset(form).then(res => {
      if(res.success) {
        showSuccessToast(i18n.global.t('e8'))
        handleClose()
      } else {
        showToast(res.msg)
      }
      loading.value = false
    })
  }

  const isSend = ref(false)
  const second = ref(59)
  let timer = null, allowClick = true
  function handleSend() {
    if(!allowClick) return
    allowClick = false
    getEmailCode().then(res => {
      if(res.success) {
        showSuccessToast(i18n.global.t('c2'))
        isSend.value = true
        timer = setInterval(() => {
          if(second.value <= 1) {
            clearInterval(timer)
            isSend.value = false
            second.value = 59
            return
          }
          second.value--
        }, 1000)
      } else {
        showToast(res.msg)
      }
      allowClick = true
    })
  }

  function openReset() {
    showMenu.value = false
    setTimeout(() => {
      show.value = true
    }, 200)
  }

  function handleExit() {
    showLoadingToast({
      message: 'Loading...',
      forbidClick: true,
    });
    logout().then(res => {
      if(res.success) {
        closeToast()
        router.push('/login')
        localStorage.removeItem('token')
      }
    })
  }

  const langText = ref('')
  langText.value = sessionStorage.getItem('langText') || 'English';

  function changeLang() {
    if(langText.value === 'English') {
      langText.value = '中文'
      sessionStorage.setItem('lang', 'zh')
    } else {
      langText.value = 'English'
      sessionStorage.setItem('lang', 'en')
    }
    sessionStorage.setItem('langText', langText.value)
    window.location.reload()
  }

  const headerStyle = ref({})
  onMounted(() => {
    window.addEventListener('scroll', onScrollChange)
  })

  onBeforeUnmount(() => {
    window.removeEventListener('scroll', onScrollChange)
    clearInterval(timer)
  })

  function onScrollChange() {
    const opacity = window.scrollY / 60 < 1 ? window.scrollY / 60 : 1
    headerStyle.value.backgroundColor = "rgba(116, 65, 192, " + opacity + ")"
  }

  const getLevelName = computed(() => {
    return val => {
      let result = ''
      switch (val) {
        case 0:
          result = 'NEW'
          break;
        case 1:
          result = 'EXPERT'
          break;
        case 2:
          result = 'PRO'
          break;
        case 3:
          result = 'V1'
          break;
        case 4:
          result = 'V2'
          break;
        case 5:
          result = 'V3'
          break;
        case 6:
          result = 'V4'
          break;
        case 7:
          result = 'V5'
          break;
      }
      return result
    }
  })

  function openUrl(url) {
    window.location.href = url
  }
  function handleClose() {
    show.value = false
    form.verification_code = ''
    form.pwd = ''
    conPwd.value = ''
  }
</script>

<style scoped lang="less">
.header{
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  z-index: 99;

  .left{
    display: flex;
    align-items: center;

    .icon_m{
      width: 24px;
      margin-right: 8px;
    }
    .logo{
      width: 77px;
    }
  }
  .right{
    display: flex;
    align-items: center;

    .message{
      margin-right: 12px;
      position: relative;

      img{
        width: 24px;
      }
      .circle{
        width: 6px;
        height: 6px;
        border-radius: 50%;
        position: absolute;
        right: -2px;
        top: 0;
        background: #ee0a24;
      }
    }
    .lang{
      display: flex;
      align-items: center;
      color: #FFFFFF;

      .icon{
        width: 22px;
        margin-right: 6px;
      }
    }
  }
}
.menu{
  height: 100%;
  padding: 60px 30px 0 20px;
  background: #F5F9FF;

  .info{
    display: flex;
    align-items: center;
    margin-bottom: 36px;

    .avatar{
      width: 56px;
      height: 56px;
      background: #8C52FF;
      border-radius: 50%;
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      img{
        width: 36px;
      }
    }
    .text{
      .name{
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 8px;
      }
      .id{
        font-size: 12px;
        color: #8C94A2;
        margin-bottom: 8px;

        span{
          font-size: 12px;
          color: #2A2A2A;
          margin-left: 4px;
        }
      }
      .level{
        font-size: 12px;
        color: #8C94A2;

        span{
          color: #7365FF;
          font-weight: bold;
          font-size: 12px;
          margin-left: 4px;
        }
      }
    }
  }
  .list{
    .item{
      width: 190px;
      height: 56px;
      background: #FFFFFF;
      border-radius: 16px;
      display: flex;
      align-items: center;
      padding-left: 18px;
      margin-bottom: 12px;

      .icon{
        width: 20px;
        margin-right: 12px;
      }
    }
  }
}
.van-popup{
  background: transparent;
}
:deep(.van-popup__close-icon){
  color: #8c94a2;
}
.reset{
  width: 303px;
  background: url("@/assets/img/popup_bg.png") no-repeat #FFFFFF;
  background-size: 100% auto;
  padding: 18px 16px;
  border-radius: 16px;

  .title{
    font-size: 16px;
    font-weight: bold;
    line-height: 23px;
    text-align: center;
  }
  .form{
    .item{
      margin-top: 20px;

      .label{
        font-size: 13px;
        font-weight: bold;
        margin-bottom: 10px;
      }
      .wrap{
        height: 44px;
        background: #F5F9FF;
        border-radius: 10px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .code{
          width: 160px;
        }
        .getCode{
          font-size: 12px;
          color: #7441C0;
          line-height: 44px;
        }
        .second{
          color: #8c94a2;
        }
      }
      .value{
        height: 44px;
        background: #F5F9FF;
        border-radius: 10px;
        padding-left: 10px;
      }
    }
    .van-button--primary{
      background: #7441C0;
      border: none;
      border-radius: 10px;
      margin-top: 20px;
      height: 42px;
    }
  }
}
</style>
